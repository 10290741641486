<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item label="药品分类">
                <el-select v-model="searchForm.categoryId" placeholder="请选择" size="small" style="width: 120px" @change="Search">
                  <el-option :label="item.categoryName" :value="item.categoryId" v-for="(item, index) in categoryData"
                    :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="是否含空库存">
                <el-radio-group v-model="searchForm.isEmpty" @change="Search">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="Search">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="批号">
                <el-input v-model="searchForm.batchNumber" type="text" size="small" placeholder="批号"
                  @keyup.enter.native="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="药品名称/商品名称/别名"
                  @keyup.enter.native="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
                <!-- <el-button type="primary" size="small" @click="addDrug()" plain>药品入库</el-button> -->
              </el-form-item>
              <!-- <el-form-item>
                <el-upload class="upload" action="#" :show-file-list="false" :on-change="handleExcel"
                  accept="'.xlsx','.xls'" :auto-upload="false" :headers="headers">
                  <el-button size="mini" type="primary" class="el-icon-upload2">导入</el-button>
                </el-upload>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }"
          height="calc(100% - 80px)">
          <el-table-column type="index" label="编号" width="50"></el-table-column>
          <el-table-column prop="mzDrugIn.mzDrugOrg.mzDrug.drugName" label="药品名称">
            <template slot-scope="scope"> {{ scope.row.mzDrugIn.mzDrugOrg.mzDrug.drugName }}
              <span v-if="scope.row.mzDrugIn.mzDrugOrg.mzDrug.drugType=='T'">(统)</span>
              <span v-if="scope.row.mzDrugIn.mzDrugOrg.mzDrug.drugType=='X'">(选)</span>
              <span v-if="scope.row.mzDrugIn.mzDrugOrg.mzDrug.drugType=='J'">(精选)</span>
            </template>
          </el-table-column>
          <el-table-column prop="mzDrugIn.mzDrugOrg.mzDrug.drugAlias" label="别名" align="center"> </el-table-column>
          <el-table-column prop="mzDrugIn.mzDrugOrg.productName" label="商品名" align="center"> </el-table-column>
          <el-table-column prop="mzDrugIn.mzDrugOrg.mzDrug.category.categoryName" label="药品分类" align="center"> </el-table-column>
          <el-table-column prop="mzDrugIn.mzDrugOrg.specification" label="规格" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.mzDrugIn.mzDrugOrg.specification">
                {{ scope.row.mzDrugIn.mzDrugOrg.specification }}/{{ scope.row.mzDrugIn.mzDrugOrg.specificationUnit }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="mzDrugIn.mzDrugOrg.productFactory" label="生产厂家" show-overflow-tooltip
            align="center"></el-table-column>
          <!-- <el-table-column prop="mzDrugOrg.supplier" label="供应商" align="center"></el-table-column> -->
          <el-table-column prop="mzDrugIn.batchNumber" label="批号" align="center">
            <template slot-scope="scope">
              {{ scope.row.mzDrugIn.batchNumber }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="putInPrice" label="进货价(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.putInPrice.toFixed(2) }}/{{ scope.row.mzDrugOrg.mzDrug.specificationUnit }}
            </template>
          </el-table-column>
          <el-table-column prop="tradingPrice" label="售价(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.tradingPrice.toFixed(2) }}/{{scope.row.mzDrugOrg.isSeparate?scope.row.mzDrugOrg.separateNumberUnit:scope.row.mzDrugOrg.mzDrug.specificationUnit }}
            </template>
          </el-table-column> -->
          <el-table-column prop="putOutNumber" label="退库数量" align="center">
            <template slot-scope="scope">
              <p style="color: red;">{{ scope.row.putOutNumber }}（{{scope.row.mzDrugIn.mzDrugOrg.isSeparate?scope.row.mzDrugIn.mzDrugOrg.separateNumberUnit:scope.row.mzDrugIn.mzDrugOrg.specificationUnit }}）</p>
            </template>
          </el-table-column>
          <el-table-column prop="mzDrugIn.inventoryNumber" label="剩余库存" align="center">
            <template slot-scope="scope">
              {{ scope.row.mzDrugIn.inventoryNumber }}（{{scope.row.mzDrugIn.mzDrugOrg.isSeparate?scope.row.mzDrugIn.mzDrugOrg.separateNumberUnit:scope.row.mzDrugIn.mzDrugOrg.specificationUnit }}）
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="操作人" align="center" width="150px"></el-table-column>
          <el-table-column prop="putOutReason" label="退库原因" align="center" width="150px">
            <template slot-scope="scope">
              <span  style="color: red;">{{ scope.row.putOutReason }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="addTime" label="添加时间" align="center" width="150px" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.addTime | timefilters }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="validDate" label="药品有效期" align="center" width="150px" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.validDate.split('T')[0] }}</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <span class="info optionBtn" @click="EditBtn(scope.row)">编辑</span>
            </template>
          </el-table-column> -->
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  data () {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      headers: { "Content-Type": "multipart/form-data;charset=UTF-8" },
      tableData: [],
      categoryData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      searchForm: {
        keyWord: '',
        batchNumber: '',
        categoryId: 0,
        isEmpty: true,
        datepicker: ''
      },
      pickerOptions0: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
      },
      title: "余额明细",
      DataCount: 0,
      json_fields: {
        订单编号: {
          filed: "key",
          callback: val => {
            return "NO：" + val.key
          },
        },
        当前余额: "name",
      },
      fileList: [],
    }
  },
  mounted () {
    this.getList()
    this.LoadCategory()
  },
  methods: {
    LoadCategory () {
      var _this = this
      _this.OutpatientDomain.GetOutpatientCategories(
        function (data) {
          _this.categoryData = data.data.categories
          _this.categoryData.unshift({ categoryId: 0, categoryName: '全部' })
        },
        function (err) {
          console.log(err)
        }
      )
    },
    addDrug () {
      this.$router.push("./DrugsWarehousing")
    },
    changePage (pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList () {
      var _this = this
      _this.tableData=[]
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00"
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59"
      } else {
        item.startTime = ""
        item.endTime = ""
      }
      _this.OutpatientDomain.DrugsOutList(item.keyWord, item.batchNumber, item.categoryId, item.isEmpty, item.startTime, item.endTime, this.pageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search (event) {
      this.pageIndex = 1
      this.getList()
    },
    // 导入数据
    handleExcel (file, fileList) {
      console.log(file.raw)
      let formData = new FormData() //声明一个FormDate对象
      formData.append("formFile", file.raw) //把文件信息放入对象中

    },
    //模板下载
    downloadTemplate () {
      let a = document.createElement("a")
      a.href = "./drug_import_template_with_branch.xls"
      a.download = "药品模板.xlsx"
      a.style.display = "none"
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    async fetchData () {
      var _this = this
      return await _this.getAllData()
    },
    getAllData () {
      var _this = this
      return new Promise((resolve, reject) => {
        // var item = _this.searchForm;
        // if (_this.searchForm.datepicker) {
        //   item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        //   item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
        // } else {
        //   item.startTime = "";
        //   item.endTime = "";
        // }
        // _this.BalanceDomain.BalanceDetail(
        //   item.startTime,
        //   item.endTime,
        //   item.keyWord,
        //   item.state,
        //   1,
        //   _this.DataCount,
        //   function (data) {
        //     resolve(data.data.results);
        //   },
        //   function (err) {
        resolve("")
        //     console.log(err);
        //   }
        // );
      })
    },
  },
}
</script>

<style scoped>.dialog-form {
  width: 85%;
}</style>
